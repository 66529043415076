.page {
  display: flex;
  flex-direction: column;

  border: 0.08rem solid var(--sweet-perfume);
  border-radius: 2rem;

  background: var(--perrywinkle-super-trans);
}
.top {
  display: flex;
  align-items: center;

  background: var(--infinity);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border-bottom: 0.08rem solid var(--sweet-perfume);
}
.bottom {
  display: flex;
  align-items: center;

  background: var(--infinity);
  border-top: 0.08rem solid var(--sweet-perfume);
  border-bottom: 0.08rem solid var(--sweet-perfume);
}
.top h1,
.bottom h1 {
  width: 25%;
  text-align: left;

  border-top-left-radius: 2rem;
}
.top h3,
.bottom h3 {
  width: 75%;
  height: 100%;
  text-align: right;

  color: var(--mangala-pink);
  font-family: "Tienne", serif;
  font-weight: 900;

  border-top-right-radius: 2rem;
}

.blogs-section {
  background: var(--infinity-trans);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
.blogs-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.blogs-list li {
  text-align: center;
  padding: 0.4rem;

  border: 0.08rem solid var(--transparent);
}
.blog {
  font-weight: 480;

  font-family: "Proza Libre", sans-serif;
  color: var(--creamy-berry);
}
.blogs-list li:hover {
  border: 0.08rem solid var(--sweet-perfume-super-trans);
}
.blog:hover {
  color: var(--mangala-pink);
  text-shadow: var(--sweet-perfume-trans) 0rem 0 0.8rem;
}
.art-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  background: var(--infinity-trans);

  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.art-frame {
  width: 40%;
  margin: 0.4rem;

  padding: 1rem;

  background: var(--sweet-perfume-super-trans);
  border: 1px solid var(--infinity);
  border-radius: 2rem;
}
.art {
  width: 100%;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
  .top,
  .bottom {
    padding: 0rem 2.4rem 0rem 2.4rem;
  }
  .top h1,
  .bottom h1 {
    padding-top: 1.6rem;
  }
  .top h3,
  .bottom h3 {
    padding-top: 2rem;
    border-left: 0.08rem double var(--sweet-perfume);
  }
  .blog {
    flex-basis: 50%;
    border-radius: 1rem;

    font-size: 1.3rem;
  }
  .blog:hover {
    border-radius: 1rem;
  }
  .blogs-list {
    padding: 0.8rem 0.4rem;
  }
  .blogs-list li {
    border-radius: 1rem;
  }
}

/* ------- tablet & mobile -------- */
@media only screen and (max-width: 899px) {
  .top,
  .bottom {
    padding: 0.8rem 1rem 0rem 1rem;
  }
  .blogs-list {
    flex-direction: column;
    padding: 0.6rem 0rem;
  }
  .blog {
    width: 100%;
    font-size: 1rem;
  }
}
