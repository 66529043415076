.outer-moon,
.rotation-ring,
.moon-face {
  position: absolute;
  height: 360px;
  width: 360px;
}
/* .outer-moon {
  box-shadow: 0rem 0rem 2rem 1.5rem rgba(91, 91, 116, 0.475);
  border-radius: 50%;
} */

#about-selected-from-blogs-and-art {
  animation: 1s linear 0s 1 normal both running rotate-about-from-blogs-and-art;
}
#about-selected-from-sites {
  animation: 1s linear 0s 1 normal both running rotate-about-from-sites;
}
#blogs-and-art-selected-from-about {
  animation: 1s linear 0s 1 normal both running rotate-blogs-and-art-from-about;
}
#blogs-and-art-selected-from-sites {
  animation: 1.8s linear 0s 1 normal both running
    rotate-blogs-and-art-from-sites;
}
#sites-selected-from-about {
  animation: 1s linear 0s 1 normal both running rotate-sites-from-about;
}
#sites-selected-from-blogs-and-art {
  animation: 1.8s linear 0s 1 normal both running
    rotate-sites-from-blogs-and-art;
}

#about-selected-from-blogs-and-art-about-box,
#about-selected-from-sites-about-box,
#blogs-and-art-selected-from-about-blogs-and-art-box,
#blogs-and-art-selected-from-sites-blogs-and-art-box,
#sites-selected-from-about-sites-box,
#sites-selected-from-blogs-and-art-sites-box {
  fill: rgb(99, 94, 188);
}

@keyframes rotate-blogs-and-art-from-about {
  from {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  to {
    transform-origin: 50% 50%;
    transform: rotate(-38deg);
  }
}
@keyframes rotate-blogs-and-art-from-sites {
  from {
    transform-origin: 50% 50%;
    transform: rotate(28deg);
  }
  to {
    transform-origin: 50% 50%;
    transform: rotate(-38deg);
  }
}
@keyframes rotate-about-from-blogs-and-art {
  from {
    transform-origin: 50% 50%;
    transform: rotate(-38deg);
  }
  to {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
}
@keyframes rotate-about-from-sites {
  from {
    transform-origin: 50% 50%;
    transform: rotate(28deg);
  }
  to {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
}
@keyframes rotate-sites-from-blogs-and-art {
  from {
    transform-origin: 50% 50%;
    transform: rotate(-38deg);
  }
  to {
    transform-origin: 50% 50%;
    transform: rotate(28deg);
  }
}
@keyframes rotate-sites-from-about {
  from {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  to {
    transform-origin: 50% 50%;
    transform: rotate(28deg);
  }
}
