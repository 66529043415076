header {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 400px;
  padding: 20px;
}
.moon-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.about-selected-from-blogs-and-art {
  animation: 1s linear 0s 1 normal both running rotate-about-from-blogs-and-art;
}
.about-selected-from-sites {
  animation: 1s linear 0s 1 normal both running rotate-about-from-sites;
}
.blogs-and-art-selected-from-about {
  animation: 1s linear 0s 1 normal both running rotate-blogs-and-art-from-about;
}
.blogs-and-art-selected-from-sites {
  animation: 1s linear 0s 1 normal both running rotate-blogs-and-art-from-sites;
}
.sites-selected-from-about {
  animation: 1s linear 0s 1 normal both running rotate-sites-from-about;
}
.sites-selected-from-blogs-and-art {
  animation: 1s linear 0s 1 normal both running rotate-sites-from-blogs-and-art;
}
.static {
  position: absolute;
  width: 360px;
  height: 360px;
}
.btn-container {
  width: 360px;
  height: 360px;

  z-index: 2;
  border-radius: 100%;
}
.rotating-btns {
  width: 360px;
  height: 360px;

  z-index: 2;
  border-radius: 100%;
}

/* set click box text color to transparent */
.btn-container a {
  color: rgba(255, 255, 255, 0);
}

.blogs-and-art-btn,
.about-btn,
.sites-btn {
  display: flex;
  position: absolute;
  border: none;
  border-radius: 2rem;

  align-items: center;
  text-align: center;
  background: none;

  z-index: 1;
}

.blogs-and-art-btn {
  height: 43px;
  width: 152px;

  top: 301px;
  left: 75px;

  rotate: 12deg;
}
.about-btn {
  height: 42px;
  width: 82px;

  top: 286px;
  left: 222px;

  rotate: -30deg;
}
.sites-btn {
  height: 34px;
  width: 64px;

  top: 234px;
  left: 283px;

  rotate: -60deg;
}
.about-btn:hover,
.blogs-and-art-btn:hover,
.sites-btn:hover {
  cursor: pointer;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
  .blogs-and-art-btn:hover,
  .about-btn:hover,
  .sites-btn:hover {
    border: 1px solid var(--sweet-perfume-super-duper-trans);
    box-shadow: 0rem 0rem 0.8rem 0.4rem var(--sweet-perfume-super-duper-trans);
  }
}
