@import url("https://fonts.googleapis.com/css2?family=Kumar+One+Outline&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Tienne:wght@400;700;900&display=swap");

/* All Colors Featured in Site */
:root {
  --umbra: #221a24;
  --infinity: #232333ff;
  --infinity-trans: rgba(35, 35, 51, 0.767);

  --shuriken: #2f2f44ff;

  --heron: #5d5d87;
  --moonraker: #bdb8d6;
  --perrywinkle: #9898dd;
  --perrywinkle-super-trans: #9898dd1b;

  --packing-paper: #bca85eff;
  --packing-paper-trans: rgba(188, 168, 94, 0.021);

  --peekaboo: #e7dfe7;
  --creamy-berry: #e0bcd5;
  --creamy-berry-trans: #e0bcd54f;

  --sweet-perfume: #dd98ba;
  --sweet-perfume-trans: #dd98bbad;
  --sweet-perfume-super-trans: #dd98bb82;
  --sweet-perfume-super-duper-trans: #dd98bb33;

  --mangala-pink: #eb8aa0;
  --happy-hearts: #d76461;

  --transparent: #ffffff00;
}

/* Fonts */
/* 
  font-family: 'Kumar One Outline', cursive;
  font-family: 'Tienne', serif;
  font-family: 'Proza Libre', sans-serif;
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100vh;

  font-family: "Proza Libre", sans-serif;
  overflow-x: hidden;

  color: var(--peekaboo);
  background-attachment: fixed;
  background-image: url("/src/assets/backgrounds/night-sky.svg");
  background-size: cover;
}
.site-content {
  width: 900px;
  margin: auto;
}
.page-content {
  margin: auto;
}

ul {
  list-style: none;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
h1,
h2 {
  color: var(--mangala-pink);
  text-shadow: var(--sweet-perfume-trans) 0rem 0 0.4rem;
  font-family: "Kumar One Outline", cursive;
}

.outer-moon,
.rotation-ring,
.moon-face {
  position: absolute;
  height: 360px;
  width: 360px;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
  .app {
    font-size: 18px;
  }
  .page-content {
    width: 900px;
  }
}

/* ------- tablet & mobile -------- */
@media only screen and (max-width: 899px) {
  .app {
    font-size: 14px;
  }
  .site-content {
    width: 100vw;
  }
  .page-content {
    width: 100vw;
  }
}
